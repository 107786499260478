<template>
  <div class="px-2">
    <KTextField
        v-model="internalFilters.plannedDateFrom"
        :field="$t('delivery.filter.plannedDateFrom')"
        prepend-icon="$calendar"
        type="date"
        clearable
    />
    <KTextField
        v-model="internalFilters.plannedDateTo"
        :field="$t('delivery.filter.plannedDateTo')"
        prepend-icon="$calendar"
        type="date"
        clearable
    />

    <NotAccordingToConditionsAutocomplete
        v-model="internalFilters.isNotAccordingToConditions"
        :field="$t('delivery.filter.isAccordingToConditions')"
        :return-object="false"
    />

    <PreadvicedAutocomplete
        v-model="internalFilters.isPreadviced"
        :field="$t('delivery.filter.preadviced')"
        :return-object="false"
    />

    <DeliveryOrderStatusAutocomplete
        v-model="internalFilters.purchaseOrderStatusIds"
        :field="$t('delivery.filter.statusName')"
        multiple
        chips
        :return-object="false"
    />

    <UserSelect
        v-model="internalFilters.assignedUserIds"
        :field="$t('delivery.filter.assignedUser')"
        multiple
        chips
        :return-object="false"
    />

    <ClientsAutocomplete
        v-model="internalFilters.clientId"
        :field="$t('delivery.filter.clientId')"
        :return-object="false"
    />
  </div>
</template>

<script lang="js">
import Vue from "vue";
import NotAccordingToConditionsAutocomplete
  from "@/components/autocompletes/NotAccordingToConditionsAutocomplete.vue";
import PreadvicedAutocomplete from "@/components/autocompletes/PreadvicedAutocomplete.vue";
import DeliveryOrderStatusAutocomplete from "@/components/autocompletes/DeliveryOrderStatusAutocomplete.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import ClientsAutocomplete from "@/components/autocompletes/ClientsAutocomplete.vue";
import UserSelect from "@/components/autocompletes/UserSelect.vue";

export default Vue.extend({
  name: "DeliveryFilterPanel",
  components: {
    UserSelect,
    ClientsAutocomplete,
    KTextField,
    DeliveryOrderStatusAutocomplete,
    PreadvicedAutocomplete,
    NotAccordingToConditionsAutocomplete,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    internalFilters: { },
  }),
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters ?? {};
      },
      deep: true,
      immediate: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
        this.$emit("update:queryParams");
      },
      deep: true,
    },
  },
})
</script>
