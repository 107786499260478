export const sendBlobToBrowserDownload = (
  fileName,
  fileExtension,
  blob
) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
};
