<template>
  <k-select
      v-model="inputVal"
      :items="items"
      v-bind="$attrs"
      v-on="$listeners"
      item-value="id"
      item-text="name"
      :return-object="false"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <VCheckbox readonly :input-value="selectedAllOptions" />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t("delivery.filter.selectAllUsers") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </k-select>
</template>

<script>
import KSelect from "@/components/crud/fields/KSelect";
import { userAutocomplete } from "@/api/endpoints/admin/user";

export default {
  name: 'UserSelect',
  components: { KSelect },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    fieldValue: [],
    items: [],
  }),
  computed: {
    selectedAllOptions() {
      return this.items.length === this.value.length;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllOptions) {
          this.inputVal = [];
        } else {
          this.inputVal = this.items.slice().map(({ id }) => id);
        }
      });
    },
  },
  async created () {
    const response = await userAutocomplete(1, 100);
    this.items = response.data.data;
  },
};
</script>
