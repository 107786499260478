<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ title }}</h1>
    <resource
        ref="resource"
        :can-search="true"
        :can-delete="false"
        :use-checkboxes="false"
        :can-add="false"
        :can-update="false"
        :index-request="index"
        :show-request="show"
        :update-request="mapAndUpdate"
        :form-component="() => import('@/components/forms/PurchaseOrderForm.vue')"
        :meta="{name: $tc('delivery.title', 1), namePlural: $tc('delivery.title', 2)}"
        :table-content="tableContent"
        :params.sync="params"
    >
      <template #afterSearchBar>
        <FilterBar
            :component="DeliveryFilterPanel"
            :filters.sync="params"
            class="mt-5 ml-2"
        />
      </template>
      <template #crudActionsAfter="{resource}">
        <v-tooltip left>
          <template #activator="{on, attrs}">
            <v-btn text
                   :color="!resource.hasMetaData ? 'grey' : ''"
                   v-bind="attrs"
                   v-on="on"
                   @click.stop="handleEditClick(resource)">A
            </v-btn>
          </template>
          {{
            resource.hasMetaData ? $t("delivery.editTooltipWithMetaData") : $t("delivery.editTooltipWithoutMetaData")
          }}
        </v-tooltip>
      </template>

      <template #speedDialAfter>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
                slot="activator"
                v-on="on"
                color="orange"
                class="export-button"
                dark
                fab
                small
                v-on:click="exportDeliveries"
            >
              <v-icon>fa-file-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("delivery.exportDeliveries") }}</span>
        </v-tooltip>
      </template>
    </resource>

  </v-container>
</template>

<script lang="js">
import i18n from "@/plugins/i18n";
import Resource from "@/components/vuetifyResource/Resource.vue";
import { index, show, update, exportClientDelivery } from "@/api/endpoints/purchaseOrder";
import { sendBlobToBrowserDownload } from "@/application/util/downloadFile";
import FilterBar from "@/components/FilterBar.vue";
import DeliveryFilterPanel from "@/views/delivery/DeliveryFilterPanel.vue";

export default {
  name: "DeliveryTable",
  components: {
    FilterBar,
    Resource,
  },
  data: () => ({
    title: i18n.tc("delivery.title", 2),
    params: {},
  }),
  computed: {
    DeliveryFilterPanel() {
      return DeliveryFilterPanel
    },
    tableContent() {
      return [
        {
          text: this.$t("delivery.table.reference"),
          value: "reference",
          class: "header-w105"
        },
        {
          text: this.$t("delivery.table.receiptNumber"),
          value: "receiptNumber",
          sortable: false,
        },
        {
          text: this.$t("delivery.table.clientName"),
          value: "clientName",
          sortable: false,
          class: "header-w115"
        },
        {
          text: this.$t("delivery.table.plannedDate"),
          value: "plannedDate",
          class: "header-w85"
        },
        {
          text: this.$t("delivery.table.unitLoad"),
          value: "unitLoad",
          sortable: false,
          class: "header-w80"
        },
        {
          text: this.$t("delivery.table.unitLoadInfo"),
          value: "unitLoadInfo",
          class: "header-w110"
        },
        {
          text: this.$t("delivery.table.statusName"),
          value: "statusName",
          class: "header-w85"
        },
        {
          text: this.$t("delivery.table.assignedUserName"),
          value: "assignedUserName",
          sortable: false,
          class: "header-w100"
        },
        {
          text: this.$t("delivery.table.inGoodsReceiptArea"),
          value: "inGoodsReceiptArea",
          sortable: true,
          class: "header-w115"
        },
        {
          text: this.$t("delivery.table.isPreadviced"),
          value: "isPreadviced",
          columnType: 'checkbox',
          class: "header-w115"
        },
        {
          text: this.$t("delivery.table.isAccordingToConditions"),
          value: "isAccordingToConditions",
          sortable: false,
          columnType: 'checkbox',
          class: "header-w110"
        },
        {
          text: this.$t("delivery.table.lastCommentDate"),
          value: "lastCommentDate",
          sortable: false,
          width: 105,
          class: "header-w105"
        },
      ];
    },
  },
  methods: {
    async exportDeliveries() {
      try {
        const response = await exportClientDelivery(this.params);
        sendBlobToBrowserDownload("deliveries", "xlsx", response.data);
      }catch (e) {
        console.error("Something went wrong with exporting purchases orders");
      }
    },
    async index(...args) {
      const response = await index(...args);
      this.title = response.data.title;

      return response;
    },
    show,
    mapAndUpdate(resource) {
      return update({
        id: resource.id,
        assignedUserId: resource.assignedUser?.id,
        statusId:resource.status?.id,
        comment: resource.comment,
        isPreadviced: resource.isPreadviced,
        isNotAccordingToConditions: resource.isNotAccordingToConditions,
        unitLoad: resource.unitLoad,
        unitLoadInfo: resource.unitLoadInfo,
        plannedDate: resource.plannedDate,
      });
    },
    handleEditClick(item) {
      this.$refs.resource.$refs.resourceList.openUpdateHandler(item.purchaseOrderId);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.vuetify-resource.with-search .v-speed-dial) {
  top: 53px;
}

:deep(.vuetify-resource th) {
  white-space: pre-line;

  &.header-w105 {
    min-width: 105px;
  }
  &.header-w115 {
    min-width: 115px;
  }
  &.header-w85 {
    min-width: 85px ;
  }
  &.header-w80 {
    min-width: 80px ;
  }
  &.header-w100 {
    min-width: 100px;
  }
  &.header-w110 {
    min-width: 110px;
  }
}
</style>
