<template>
  <KSelect :field="field" :items="items" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KSelect from "@/components/crud/fields/KSelect.vue";

export default {
  name: 'NotAccordingToConditionsAutocomplete',
  components: { KSelect },
  props: {
    field: {
      type: String,
      required: true,
    },
  },
  computed: {
    items() {
      return [
        { text: this.$t('delivery.enums.accordingToConditions.all'), value: null },
        { text: this.$t('delivery.enums.accordingToConditions.notAccording'), value: 1 },
        { text: this.$t('delivery.enums.accordingToConditions.isAccording'), value: 0 },
      ]
    }
  },
};
</script>
